import React, { useState, useEffect, useMemo } from "react";
import { withFirebase } from "../../../../../../utils/Firebase";
import Select from "../../../../../atoms/Select";
import Input from "../../../../../atoms/Input";
import Button from "../../../../../atoms/Button";
import countryData from "../../../../../../../static/new-world-110m.json";
import { numOfInvitesOptions } from "../../../../../../constants/inviteOptions";
import getCountryFlag from "../../../../../../scripts/getCountryFlag";
import CountryCodes from "../../../../../../data/countryISOcodes.json";
import MinistrySelect from "../../../../../molecules/ministrySelect";

const getCountryOptions = (regions) => {
  let countries = [];
  countryData.objects.gadm36_0.geometries.forEach((c) => {
    if (regions[0] === "all" || regions.indexOf(c.properties.GID_0) !== -1) {
      countries.push({
        value: c.properties.GID_0,
        label: c.properties.NAME_0,
      });
    }
  });
  return countries.sort((a, b) => {
    return a.label > b.label ? 1 : -1;
  });
};

const CreateUserFormBase = ({ firebase }) => {
  const [email1, setEmail1] = useState("");
  const [checkBox1F, setCheckBox1F] = useState(false);
  const [checkBox1T, setCheckBox1T] = useState(false);
  const [numOfInvites1, setNumOfInvites1] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedMinistry, setSelectedMinistry] = useState("");
  const [selectedMinistryData, setSelectedMinistryData] = useState("");

  useEffect(() => {
    if (firebase && firebase.auth.currentUser && !currentUser) {

      const { currentUser } = firebase.auth;
      firebase
        .user(currentUser.uid)
        .get()
        .then((snapshot) => {
          const dbUser = snapshot.data();

          // merge auth and db user
          const authUser = {
            uid: currentUser.uid,
            email: currentUser.email,
            ...dbUser,
          };
          setSelectedCountry(
            dbUser.regions.length === 1 && dbUser.regions[0] !== "all"
              ? dbUser.regions
              : ""
          );
          setCurrentUser(authUser);
          if (!currentUser.ministries) {
            setCheckBox1F(true);
          }
        });
    }
  }, [firebase, currentUser]);

  const getAvailableEmails = () => {

    const emailInputs = [
      [
        email1.trim(),
        checkBox1F ? "FACILITATOR" : checkBox1T ? "TEAMLEADER" : null,
        checkBox1T ? numOfInvites1 : checkBox1F ? 10 : null,
      ],
    ];
    const arrayOfEmails = emailInputs.filter((e) => emailIsValid(e[0]));
    return arrayOfEmails;
  };

  const createEmailDataSets = async (emails) => {

    const sets = await emails.map(async (email, index) => {

      let randomPassword = null;
      let uid = null;
      let existingUserData = null;
      const snapshot = await firebase.getUserByEmail(email[0]);
      if (snapshot.empty) {
        console.log("No matching users.");
      }
      snapshot.forEach((doc) => {
        existingUserData = doc.data();
      });

      if (!existingUserData) {
        // Create Access Code
        randomPassword = Array(10)
          .fill("123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnopqrstuvwxyz")
          .map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
          })
          .join("");
        // Create UID
        uid = await firebase
          .createUniqueUid("users")
          .then((id) => id)
          .catch((err) =>
            console.log(
              "[createUserFormBase] firebase.createUniqueId [user]",
              err
            )
          );
      } else {
        uid = existingUserData.uid;
      }

      let ministryId =
        selectedMinistry && selectedMinistry !== ""
          ? selectedMinistry
          : await firebase
              .createUniqueUid("ministry")
              .then((id) => id)
              .catch((err) =>
                console.log(
                  "[createUserFormBase] firebase.createUniqueId [ministry]",
                  err
                )
              );

      let teamId = null;
      // if (email[1] === "TEAMLEADER" && (selectedMinistry || ministryId)) {
      teamId = await firebase
        .createUniqueUid("teams")
        .then((id) => id)
        .catch((err) =>
          console.log(
            "[createUserFormBase] firebase.createUniqueId [team]",
            err
          )
        );
      if (teamId) {
        await firebase
          .team(teamId)
          .set({
            teamId: teamId,
            invites: +email[2],
            leaders: [uid],
            ministryId: ministryId,
            facilitator: email[1] === "TEAMLEADER" ? currentUser.uid : uid,
            email: email[0],
            country: selectedMinistryData.country || selectedCountry,
            name: "Edit Team Name",
          })
          .then(() => console.log(`team created!`))
          .catch((err) =>
            console.log("[createUserFormBase] firebase.team", err)
          );
      }
      console.log("ministryId", selectedMinistry, "teamId", teamId);
      if (!selectedMinistry || selectedMinistry === "") {

        if (ministryId) {
          setSelectedMinistry(ministryId);
          await firebase
            .ministry(ministryId)
            .set({
              ministryId,
              facilitators: [uid],
              name: "Ministry Name",
              country: selectedCountry,
              contactEmail: email[0],
              ministryLink: "www.sharebibles.com",
              donationLink: "",
              description: "Describe your ministry here.",
              teams: [teamId],
              public: false,
            })
            .then(() =>
              console.log(
                `ministry created!`,
              )
            )
            .catch((err) =>
              console.log("[createUserFormBase] firebase.ministry", err)
            );
        }
      } else if (selectedMinistry && email[1] === "FACILITATOR") {

        await firebase
          .addFacilitatorToMinistryData(selectedMinistry, uid)
          .catch((err) =>
            console.log(
              "[createUserFormBase] firebase.addFacilitatorToMinistryData",
              err
            )
          );
      }

      // Create TeamId (if "Team Leader")
      // Teams MUST have a ministry

      const roles = !email[1]
        ? []
        : email[1] === "FACILITATOR"
        ? ["FACILITATOR", "TEAMLEADER"]
          : [email[1]];
      console.log("before regions", selectedCountry, selectedMinistry, currentUser);
      const regions = selectedCountry
        ? [selectedCountry.toString()]
        : selectedMinistryData
        ? [selectedMinistryData.country]
        : currentUser.regions;
      const facilitators =
        email[1] === "FACILITATOR" ? [uid] : [currentUser.uid];

      return {
        uid,
        email: email[0],
        accessCode: randomPassword || "1a2b3c4d5e",
        facilitators: existingUserData
          ? [...new Set([...existingUserData.facilitators, ...facilitators])]
          : facilitators,

        roles: existingUserData
          ? [...new Set([...existingUserData.roles, ...roles])]
          : roles,
        teams: existingUserData
          ? [...existingUserData.teams, teamId]
          : [teamId],
        regions: existingUserData
          ? [...existingUserData.regions, ...regions]
          : regions,
        ministries: existingUserData
          ? [...existingUserData.ministries, ministryId]
          : [ministryId],
      };
    });
    return sets;
  };

  const onSubmit = async (event) => {
    const validEmails = getAvailableEmails();
    const emailDataSets = await createEmailDataSets(validEmails).then(
      (result) => result
    );

    await emailDataSets.forEach(async (newUser) => {
      await newUser.then(async (data) => {
        await firebase
          .user(data.uid)
          .set(data)
          .then(() => {
            alert(
              `The following accounts was successfully added:${"\n"}${
                data.email
              }${"\n"}${data.uid}`
            );
          })
          .catch((err) => alert(`firestore didn't save user data`, err));
      });
    });

    setError(error);
    setCheckBox1F(false);
    setCheckBox1T(false);
    setEmail1("");

    event.preventDefault();
  };

  const checkTheBox = (event) => {
    switch (event.target.name) {
      case "checkBox1F":
        setCheckBox1F(!checkBox1F);
        setCheckBox1T(false);
        break;
      case "checkBox1T":
        setCheckBox1T(!checkBox1T);
        setCheckBox1F(false);
        break;
    }
  };

  const emailIsValid = (email) => {
    if (email.includes("@") && email.includes(".")) {
      return true;
    }
    return false;
  };

  const isAdmin = currentUser && currentUser.roles.indexOf("ADMIN") !== -1;
  const isFacilitator =
    currentUser && currentUser.roles.indexOf("FACILITATOR") !== -1;

  const isValid = emailIsValid(email1);

  const showMinistryDropDown = currentUser && currentUser.ministries;

  const showCountryDropDown =
    currentUser &&
    (currentUser.regions.indexOf("all") !== -1 ||
      currentUser.regions.length > 2);

  const changeCountry = (country) => {
    setSelectedCountry(country);
    setSelectedMinistry("");
  };

  return (
    <div>
      {showCountryDropDown && (
        <div>
          <Select
            options={getCountryOptions(currentUser.regions)}
            labelName="Select Country"
            className
            value={selectedCountry}
            setValue={changeCountry}
          />
        </div>
      )}
      {showMinistryDropDown && (
        <MinistrySelect
          selectedMinistry={selectedMinistry}
          setSelectedMinistry={setSelectedMinistry}
          setSelectedMinistryData={setSelectedMinistryData}
        />
      )}
      <div className="createUserForm">
        <Input
          name="email1"
          value={email1}
          onChange={(event) => {
            const text = event.target.value.replace("mailto:", "");
            setEmail1(text);
          }}
          type="text"
          required={true}
          labelName="New User Email"
        />
        <div className="createUserForm__roles">
          <div className="createUserForm__checkbox">
            <input
              name="checkBox1F"
              type="checkbox"
              checked={checkBox1F}
              onChange={(event) => checkTheBox(event)}
            />
            <p className="createUserForm__checkbox__label">Facilitator</p>
          </div>
          {selectedMinistry && (
            <div className="createUserForm__checkbox">
              <input
                name="checkBox1T"
                type="checkbox"
                checked={checkBox1T}
                onChange={(event) => checkTheBox(event)}
              />
              <p className="createUserForm__checkbox__label">Team Leader</p>
            </div>
          )}
          {checkBox1T && (
            <div className="createUserForm__invitesSelect">
              <Select
                options={numOfInvitesOptions}
                labelName="Number of Invites"
                value={numOfInvites1}
                setValue={setNumOfInvites1}
              />
            </div>
          )}
        </div>
        {currentUser && currentUser.ministries.length === 0 && (
          <p className="error">
            You need to setup a ministry before you can create team leaders
          </p>
        )}
      </div>
      {(isAdmin || isFacilitator) && (
        <Button
          variant="outlined"
          type="submit"
          disabled={!isValid || !currentUser || (!isAdmin && !selectedMinistry)}
          onClick={onSubmit}
          text="Create Users"
        />
      )}
    </div>
  );
};

export default withFirebase(CreateUserFormBase);
