import React from "react";
import { compose } from "recompose";
import Layout from "../utils/layout";
import { withAuthorization, withEmailVerification } from "../utils/Session";
import CreateUser from "../components/scenes/Auth/scenes/CreateUser/CreateUser";

const condition = (authUser) => !!authUser;
const CreateUserPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(CreateUser);

export default () => {
  return (
    <Layout>
      <CreateUserPage />
    </Layout>
  );
};
