export default cc => {
  if (!cc) return '';
  // Mild sanity check.
  if (cc.length !== 2) return cc;

  // Convert char to Regional Indicator Symbol Letter
  function risl(chr) {
    return String.fromCodePoint(
      0x1f1e6 - 65 + chr.toUpperCase().charCodeAt(0),
    );
  }

  // Create RISL sequence from country code.
  return risl(cc[0]) + risl(cc[1]);
};
